application.register("part_ui_wsw", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element;

        Array.from(element.querySelectorAll("table")).filter((table) => {
            self.wrap(table, new DOMParser().parseFromString(`<div class="elm_table"></div>`, 'text/html').body.firstChild);
        });

        Array.from(element.querySelectorAll("iframe")).filter((iframe) => {
            if (iframe.src.match(/youtube\.com/i)) {
                self.wrap(iframe, new DOMParser().parseFromString(`<div class="elm_video"></div>`, 'text/html').body.firstChild);
            }
        });

        Array.from(element.querySelectorAll("video")).filter((video) => {
            video.removeAttribute("controls");
            self.wrap(video, new DOMParser().parseFromString(`<div class="part_ui_video" data-ratio="16/9" data-controller="part_ui_video" data-action="click->part_ui_video#togglePlay"></div>`, 'text/html').body.firstChild);
        });

        element.querySelectorAll('[target="_blank"]').forEach((element) => {
            element.setAttribute("data-no-swup", true);
        })
    }

    wrap(el, wrapper) {
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }
});
